<template>
  <div class="pageContol sjzRegulatorClassReview">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl searchboxfl">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectCode"
                placeholder="请输入班级编码"
                size="small"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectName"
                placeholder="请输入班级名称"
                size="small"
                clearable
              />
            </div>
            <div title="开始时间" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                v-model="searchData.dateRange"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                clearable
                class="listInput"
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
                class="listInput"
              ></el-cascader>
            </div>
            <div title="班级状态" class="searchboxItem ci-full">
              <span class="itemLabel">班级状态:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.projectState"
                placeholder="请选择班级状态"
                clearable
              >
                <el-option
                  v-for="item in classStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                v-model="searchData.endDateRange"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                clearable
                class="listInput"
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'auditTime', order: 'descending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="班级编码"
                align="center"
                show-overflow-tooltip
                prop="projectCode"
                width="150px"
              />
              <el-table-column
                label="班级名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                width="200"
              />
              <el-table-column
                label="负责人"
                align="center"
                show-overflow-tooltip
                prop="projectUser"
                minWidth="60"
              />
              <el-table-column
                label="负责人电话"
                align="center"
                show-overflow-tooltip
                prop="projectUserPhone"
                minWidth="100"
              />
              <el-table-column
                label="行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaNamePath"
                minWidth="120"
              />
              <el-table-column
                label="行业/工种"
                align="center"
                show-overflow-tooltip
                prop="occupationNamePath"
              />
              <el-table-column
                label="培训等级"
                align="center"
                show-overflow-tooltip
                prop="trainLevelName"
              />
              <el-table-column
                label="总学时"
                align="center"
                show-overflow-tooltip
                prop="totalPeriod"
              />
              <el-table-column
                label="开始时间"
                align="center"
                show-overflow-tooltip
                prop="startDate"
                minWidth="90"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.startDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endDate"
                minWidth="90"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.endDate | momentDate }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="班级状态"
                align="center"
                show-overflow-tooltip
                prop="projectState"
              >
                <template slot-scope="scope">
                  {{ getclassstatusList(scope.row.projectState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="提交审核时间"
                align="center"
                show-overflow-tooltip
                prop="auditTime"
                minWidth="135"
                :sort-orders="['ascending', 'descending']"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.auditTime | moment }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="
                      seeDetails(
                        scope.row.projectId,
                        scope.row.projectState,
                        scope.row.projectSource
                      )
                    "
                    >审核详情</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    :disabled="!scope.row.enableConfigParam"
                    @click="
                      seeclassparameters(
                        scope.row.projectId,
                        scope.row.projectCrowd
                      )
                    "
                    >班级参数</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "sjzRegulator/classReview",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        projectCode: "", //班级编码
        projectName: "", //班级名称
        dateRange: [], //开始时间
        endDateRange: [], //结束时间
        areaId: "", //行政区划
        projectState: "", //班级状态
        auditTimeStort: "descending", //排序
      },
      classStatusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待审核",
          value: "25",
        },
        {
          label: "已通过",
          value: "30",
        },
        {
          label: "未通过",
          value: "45",
        },
        {
          label: "培训中",
          value: "50",
        },
        {
          label: "培训结束",
          value: "60",
        },
      ], //班级状态list
      areatreeList: [], //行政区域list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getTableHeight();
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    init() {
      this.getareatree();
      
      this.getData(-1);
    },
    //详情
    seeDetails(projectId, projectState, projectSource) {
      this.$router.push({
        path: "/web/SupervisionEnd/classCheckDetail",
        query: {
          id: projectId,
          projectState: projectState,
          projectSource,
        },
      });
    },
    //班级参数
    seeclassparameters(projectId, personType) {
      this.$router.push({
        path: "/web/SupervisionEnd/classparametersSJZ",
        query: {
          projectId,
          personType,
          stu: "edit",
        },
      });
    },
    //排序
    changeSort(val) {
      this.searchData.auditTimeStort = val.order;
      this.getData();
    },
    getData(pageNum = 1) {
      const params = {
        auditTimeStort: this.searchData.auditTimeStort,
        dateRange: this.searchData.dateRange,
        endDateRange: this.searchData.endDateRange,
        pageSize: this.pageSize,
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }

      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.projectState) {
        params.projectState = this.searchData.projectState;
      }
      this.doFetch({
        url: "/gov/project/rollCallauditListPage",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getclassstatusList(value) {
      let classstatuslist = [
        {
          label: "未申请",
          value: "10",
        },
        {
          label: "待审核",
          value: "25",
        },
        {
          label: "已通过",
          value: "30",
        },
        {
          label: "未通过",
          value: "45",
        },
        {
          label: "培训中",
          value: "50",
        },
        {
          label: "培训结束",
          value: "60",
        },
      ];
      for (let i in classstatuslist) {
        if (value == classstatuslist[i].value) {
          return classstatuslist[i].label;
        }
      }
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
